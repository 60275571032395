import { Header } from "@/components/Header";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogClose, DialogTrigger } from "@/components/ui/dialog";
import { DialogContent } from "@radix-ui/react-dialog";

const memesUrl = ["memes/factory.jpg", "memes/liberal.jpg", "memes/orig.jpg"];

function App() {
  return (
    <>
      <Header />
      <div className="wrapper flex flex-col mx-auto items-center space-y-5">
        {memesUrl.map((url, i) => (
          <MemeCard key={url + i} url={url} />
        ))}
      </div>
    </>
  );
}

function MemeCard({ url, key }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Card className={"w-2/3 cursor-pointer"} key={key}>
          <CardHeader>
            <CardTitle>meme</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-row justify-center">
            <img
              className="max-h-96 visible"
              src={url}
              alt={url.match(/^[^.]+/)?.[0]}
            />
          </CardContent>
        </Card>
      </DialogTrigger>
      <DialogContent className="fixed  bottom-0 flex flex-row justify-center items-center bg-black bg-opacity-70 outline-none w-[100vw] h-[100vh]">
        <DialogClose className="flex flex-row justify-center items-center outline-none grow">
            <img className="max-h-[100vh] max-w-[100vw]" src={url} alt={url.match(/^[^.]+/)?.[0]} />
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
}

export default App;
