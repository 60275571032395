import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar.jsx";

export function Header() {
  return (
    <header className="flex flex-row items-center justify-between">
      {/* logo */}
      <div>
        <Avatar>
          <AvatarImage src={"favicon.ico"} alt="logo" />
          <AvatarFallback>Logo</AvatarFallback>
        </Avatar>
      </div>

      {/* who is developer */}
      <div className="flex flex-row items-center">
        <p>This site developed by </p>
        <a
          className="ml-2 text-lg font-semibold text-orange-400"
          href="https://t.me/Probka_da"
          target="_blank"
          rel="noreferrer"
        >
          Probka_da
        </a>

        <Avatar className={"ml-2"}>
          <AvatarImage
            src={
              "https://cdn4.cdn-telegram.org/file/uGbNv2EddQn-8EdEgRmBBOhdOVUOHQgO1i4L0p7h6W9hA5LrmANmNjBK7iUePq9c4_d3ohRSKRWBuP2bYiNO8WN3gTWs3naqAm6ncR6ck5Va3vook1cO3cPAyyCe2iCY1RBeu1MBJibkpmzugDqFVEMNB_5nJqNro5m2GOwF1qwVO2MfReh7O3ss9aTUaW_KszmYsNJ24qZGd-KcO4DXOqF9ZtTRXNH5_qeS2n3F9wr8V9GSVFlcUX8DMmjh8_KNEmPQIuyd6MVkdHZaK3zxEwP3hFpg090c5tMtpyL4IP2oguuw5WnI0qXgc1io1eW5HPT9Xew6C7xV62iOPLTLPw.jpg"
            }
            alt={"avatar"}
          />
          <AvatarFallback>Avatar</AvatarFallback>
        </Avatar>
      </div>
    </header>
  );
}
